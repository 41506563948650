import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(App, {}));
document.addEventListener('wheel', function (event) {
    if (document?.activeElement?.type === 'number') {
        ;
        document.activeElement.blur();
    }
});
