// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media print {
  .hidden-print {
    display: none !important;
  }

  .page {
    size: landscape;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input[type=number] {
    -moz-appearance:textfield !important;
}

/* .ui.container {
  width: 1200px !important;
} */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;;;AAGA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;;IAEI,mCAAmC;IACnC,oBAAoB;AACxB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;;GAEG","sourcesContent":["body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n\n@media print {\n  .hidden-print {\n    display: none !important;\n  }\n\n  .page {\n    size: landscape;\n  }\n}\n\ninput[type=number]::-webkit-outer-spin-button,\ninput[type=number]::-webkit-inner-spin-button {\n    -webkit-appearance: none !important;\n    margin: 0 !important;\n}\n\ninput[type=number] {\n    -moz-appearance:textfield !important;\n}\n\n/* .ui.container {\n  width: 1200px !important;\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
