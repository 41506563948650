import { jsx as _jsx } from "react/jsx-runtime";
import './App.css';
import 'fomantic-ui-css/semantic.min.css';
import { AuthProvider } from './context/auth';
import AppRouter from './AppRouter';
import { AppProvider } from './context/appContext';
import ErrorBoundary from './views/ErrorBoundary';
function App() {
    // api.setBaseUrl('http://localhost:1337')
    // TODO: this is only needed if the backend is running on another base path, wich it is currently in the devcontainer setup. The proxy should be used in the docker compose file wich would make this unnecessary
    // api.setBaseUrl('')
    return (_jsx(AuthProvider, { children: _jsx(AppProvider, { children: _jsx(ErrorBoundary, { children: _jsx(AppRouter, {}) }) }) }));
}
export default App;
