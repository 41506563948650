import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import { Form, Grid, Header, Image, Message } from 'semantic-ui-react';
import { AuthContext } from '../context/auth';
import api from '../utils/api';
import Logo from '../assets/logo_operations.svg';
function LoginView() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [hasError, setHasError] = useState(false);
    const { setUser, setUserSettings } = useContext(AuthContext);
    const login = useCallback(() => {
        setHasError(false);
        api.setToken('');
        api
            .post('/api/auth/local', { identifier: username, password })
            .then((r) => {
            if (r.ok) {
                void r.json().then((o) => {
                    if (o.user?.id != null && o.user.id > 0) {
                        setUser({
                            id: o.user.id,
                            name: o.user.name,
                            surname: o.user.surname,
                            token: o.jwt,
                            role: String(o.user.role.name).toUpperCase()
                        });
                        setUserSettings({
                            opsFilter: o.user.id,
                            activeFixtureView: null,
                            activeFixtureTypeView: null,
                            confOfReceiptFilter: false
                        });
                        api.setToken(o.jwt);
                        const redirectUri = localStorage.getItem('redirect-uri');
                        if (redirectUri != null && redirectUri.length > 0) {
                            localStorage.removeItem('redirect-uri');
                            window.location.assign(redirectUri);
                        }
                        else {
                            window.location.assign('/');
                        }
                        localStorage.setItem('current-user', JSON.stringify({
                            id: o.user.id,
                            name: o.user.name,
                            surname: o.user.surname,
                            token: o.jwt,
                            role: String(o.user.role.name).toUpperCase()
                        }));
                        localStorage.setItem('current-user-settings', JSON.stringify({
                            opsFilter: o.user.id,
                            activeFixtureView: null,
                            activeFixtureTypeView: null,
                            confOfReceiptFilter: false
                        }));
                    }
                });
            }
            else {
                setHasError(true);
            }
        })
            .catch((err) => {
            console.error(err);
            setHasError(true);
        });
    }, [password, setUser, setUserSettings, username]);
    return (_jsx(Grid, { centered: true, style: { height: '100vh' }, textAlign: "center", verticalAlign: "middle", children: _jsxs(Grid.Column, { style: { maxWidth: 450 }, children: [_jsx(Image, { centered: true, size: "small", src: Logo }), _jsx(Header, { as: "h2", textAlign: "center", children: "Log-in to your account" }), _jsxs(Form, { onSubmit: (e) => {
                        e.preventDefault();
                    }, children: [_jsx(Form.Input, { label: "Username", onChange: (_, d) => {
                                setUsername(d.value);
                            } }), _jsx(Form.Input, { label: "Password", onChange: (_, d) => {
                                setPassword(d.value);
                            }, type: "password" }), _jsx(Message, { content: "User name or password are incorrect.", error: true, header: "Invalid login data", visible: hasError }), _jsx(Form.Button, { floated: "right", onClick: login, children: "Login" })] })] }) }));
}
export default LoginView;
