import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useCallback, useContext } from 'react';
import { updatePortInfos } from 'src/hooks/useCalculateDemurrage';
export const AppContext = createContext(undefined);
export function useAppContext() {
    const state = useContext(AppContext);
    if (state === undefined) {
        throw new Error('AppState Undefined');
    }
    return state;
}
export function AppProvider({ children }) {
    const [state, setState] = useState({
        loading: false,
        saveAll: false,
        dirty: false,
        selected: {
            spotFixture: {},
            spotFixtureInput: {},
            tcFixture: {},
            tcFixtureInput: {}
        },
        spotFixtureMap: {
            value: undefined,
            loading: false
        },
        tcFixtureMap: {
            value: undefined,
            loading: false
        },
        filteredFixtures: [],
        offhireTimes: {
            value: undefined,
            loading: false
        },
        charterers: {
            value: undefined,
            loading: false
        },
        companies: {
            value: undefined,
            loading: false
        },
        coBrokers: {
            value: undefined,
            loading: false
        },
        owners: {
            value: undefined,
            loading: false
        },
        portAgents: {
            value: undefined,
            loading: false
        },
        ports: {
            value: undefined,
            loading: false
        },
        users: {
            value: undefined,
            loading: false
        },
        vessels: {
            value: undefined,
            loading: false
        },
        quantityPrefixes: {
            value: undefined,
            loading: false
        },
        activeAdditionalFields: {
            claims: false,
            commissions: false,
            demurrage: false
        },
        userMap: {
            loading: false,
            value: undefined
        },
        config: {
            loading: false,
            value: undefined
        },
        cargoInfos: [],
        customDemurrageTimes: [],
        differentiales: [],
        dischargePorts: {},
        freightInputs: [],
        otherClaims: [],
        recaps: [],
        loadPorts: {}
    });
    const copyFixtureValuesToFixtureInput = useCallback(function () {
        setState((old) => {
            const cargoInfos = {};
            const loadPorts = {};
            const dischargePorts = {};
            const customDemurrageTimes = {};
            for (const ci of old.selected.spotFixture?.attributes?.cargo_infos
                ?.data ?? []) {
                const ciId = Number(ci.id);
                if (ciId > 0) {
                    cargoInfos[ciId] = {
                        ...ci.attributes,
                        load_port_infos: ci.attributes?.load_port_infos?.data
                            ?.filter((pi) => pi.id !== undefined)
                            .map((pi) => Number(pi.id)),
                        discharge_port_infos: ci.attributes?.discharge_port_infos?.data
                            ?.filter((pi) => pi.id !== undefined)
                            .map((pi) => Number(pi.id)),
                        quantity_prefix: ci.attributes?.quantity_prefix?.data?.id
                    };
                    loadPorts[ciId] =
                        ci.attributes?.load_port_infos?.data?.map((pi) => {
                            customDemurrageTimes[Number(pi.id)] =
                                pi.attributes?.custom_demurrage_times?.data?.map((cdt) => {
                                    return {
                                        cargoInfoId: ciId,
                                        data: cdt.attributes ?? {},
                                        portInfoId: Number(pi.id),
                                        id: cdt.id
                                    };
                                }) ?? [];
                            return pi;
                        }) ?? [];
                    loadPorts[ciId] = updatePortInfos(loadPorts[ciId], customDemurrageTimes);
                    dischargePorts[ciId] =
                        ci.attributes?.discharge_port_infos?.data?.map((pi) => {
                            customDemurrageTimes[Number(pi.id)] =
                                pi.attributes?.custom_demurrage_times?.data?.map((cdt) => {
                                    return {
                                        cargoInfoId: ciId,
                                        data: cdt.attributes ?? {},
                                        portInfoId: Number(pi.id),
                                        id: cdt.id
                                    };
                                }) ?? [];
                            return pi;
                        }) ?? [];
                    dischargePorts[ciId] = updatePortInfos(dischargePorts[ciId], customDemurrageTimes);
                }
            }
            return {
                ...old,
                dirty: false,
                cargoInfos,
                loadPorts,
                dischargePorts,
                customDemurrageTimes,
                recaps: [],
                selected: {
                    ...old.selected,
                    spotFixtureInput: (() => {
                        return {
                            ...old.selected.spotFixture?.attributes,
                            charterer_company: old.selected.spotFixture?.attributes?.charterer_company?.data
                                ?.id ?? undefined,
                            co_broker: old.selected.spotFixture?.attributes?.co_broker?.data?.id ??
                                undefined,
                            inhouse_broker: old.selected.spotFixture?.attributes?.inhouse_broker?.data
                                ?.id ?? undefined,
                            cargo_infos: old.selected.spotFixture?.attributes?.cargo_infos?.data?.map((ci) => {
                                return {
                                    ...ci.attributes,
                                    discharge_port_infos: ci.attributes?.discharge_port_infos?.data?.map((p) => p.id),
                                    load_port_infos: ci.attributes?.load_port_infos?.data?.map((p) => p.id)
                                };
                            }),
                            vessel: old.selected.spotFixture?.attributes?.vessel?.data?.id ??
                                undefined,
                            operator: old.selected.spotFixture?.attributes?.operator?.data?.id ??
                                undefined,
                            owner_company: old.selected.spotFixture?.attributes?.owner_company?.data?.id ??
                                undefined,
                            loi_sent: old.selected.spotFixture?.attributes?.loi_sent ?? undefined,
                            loading_sent: old.selected.spotFixture?.attributes?.loading_sent ?? undefined,
                            discharge_sent: old.selected.spotFixture?.attributes?.discharge_sent ??
                                undefined,
                            recaps: (old.selected.spotFixture?.attributes?.recaps?.data ?? [])
                                .filter((p) => p.id != null)
                                .map((p) => Number(p.id)),
                            other_claims: (old.selected.spotFixture?.attributes?.other_claims?.data ?? [])
                                .filter((p) => p.id != null)
                                .map((p) => Number(p.id)),
                            freight_differentials: (old.selected.spotFixture?.attributes?.freight_differentials
                                ?.data ?? [])
                                .filter((p) => p.id != null)
                                .map((p) => Number(p.id)),
                            ws_overage: old.selected.spotFixture?.attributes?.ws_overage ?? 100
                        };
                    })(),
                    tcFixtureInput: (() => {
                        return {
                            ...old.selected.tcFixture?.attributes,
                            charterer_company: old.selected.tcFixture?.attributes?.charterer_company?.data
                                ?.id ?? undefined,
                            co_broker: old.selected.tcFixture?.attributes?.co_broker?.data?.id ??
                                undefined,
                            vessel: old.selected.tcFixture?.attributes?.vessel?.data?.id ??
                                undefined,
                            operator: old.selected.tcFixture?.attributes?.operator?.data?.id ??
                                undefined,
                            owner_company: old.selected.tcFixture?.attributes?.owner_company?.data?.id ??
                                undefined,
                            inhouse_broker: old.selected.tcFixture?.attributes?.inhouse_broker?.data?.id ??
                                undefined,
                            offhire_times: (old.selected.tcFixture?.attributes?.offhire_times?.data ?? [])
                                .filter((ot) => ot != null)
                                .map((ot) => Number(ot.id)),
                            delivery_port: old.selected.tcFixture?.attributes?.delivery_port?.data?.id ??
                                undefined,
                            recaps: (old.selected.tcFixture?.attributes?.recaps?.data ?? [])
                                .filter((p) => p.id != null)
                                .map((p) => Number(p.id))
                        };
                    })()
                }
            };
        });
    }, []);
    return (_jsx(AppContext.Provider, { value: {
            copyFixtureValuesToFixtureInput,
            setState,
            state
        }, children: children }));
}
