import { useEffect } from 'react';
import { getSafeDateRaw, notEqualFloat } from 'src/utils/helper';
export function updatePortInfos(portInfos, customDemurrageTimes) {
    return portInfos?.map((p) => {
        const startDate = getSafeDateRaw(p.attributes != null
            ? p.attributes[p.attributes.start_field]
            : undefined);
        const stopDate = getSafeDateRaw(p.attributes != null
            ? p.attributes[p.attributes.stop_field]
            : undefined);
        if (customDemurrageTimes[Number(p?.id)] == null) {
            customDemurrageTimes[Number(p?.id)] = [];
        }
        const totalDuration = ((stopDate?.getTime() ?? 0) - (startDate?.getTime() ?? 0)) / 1000 / 60;
        const durationToIgnore = customDemurrageTimes[Number(p?.id)].reduce((sum, cdt) => {
            const start = getSafeDateRaw(cdt.data.start)?.getTime();
            const stop = getSafeDateRaw(cdt.data.stop)?.getTime();
            if (start !== undefined &&
                stop !== undefined &&
                Number(cdt.data?.fraction) > 0) {
                return (sum +
                    (stop - start) / 1000 / 60 / (100 / Number(cdt.data?.fraction)));
            }
            return sum;
        }, 0);
        const relevantDuration = totalDuration - durationToIgnore;
        if (notEqualFloat(relevantDuration, p.attributes?.used_laytime) &&
            p.attributes?.used_in_demurrage_calculation === true) {
            return {
                ...p,
                attributes: {
                    ...p.attributes,
                    used_laytime: relevantDuration
                }
            };
        }
        return p;
    });
}
export function useCalculateDemurrage({ setState, state }) {
    useEffect(() => {
        if (state.selected.spotFixtureInput != null) {
            const customDemurrageTimes = state.customDemurrageTimes;
            const loadPorts = state.loadPorts;
            const dischargePorts = state.dischargePorts;
            const cargoInfos = state.cargoInfos;
            let totalUsedLaytime = 0;
            let hasChanges = false;
            for (const k in cargoInfos) {
                loadPorts[k] = updatePortInfos(loadPorts[k] ?? [], customDemurrageTimes);
                dischargePorts[k] = updatePortInfos(dischargePorts[k] ?? [], customDemurrageTimes);
                let usedLaytime = loadPorts[k]
                    .filter((p) => p.attributes?.used_in_demurrage_calculation)
                    ?.map((p) => Number(p.attributes?.used_laytime))
                    .reduce((a, b) => a + b, 0);
                usedLaytime += dischargePorts[k]
                    .filter((p) => p.attributes?.used_in_demurrage_calculation)
                    ?.map((p) => Number(p.attributes?.used_laytime))
                    .reduce((a, b) => a + b, 0);
                totalUsedLaytime += usedLaytime;
                if (notEqualFloat(cargoInfos[k]?.used_laytime, usedLaytime)) {
                    hasChanges = true;
                    cargoInfos[k] = {
                        ...cargoInfos[k],
                        used_laytime: usedLaytime
                    };
                }
            }
            const calculatedDemurrage = Number((Number(totalUsedLaytime) / 60 -
                Number(state.selected.spotFixtureInput?.laytime)) *
                (Number(state.selected.spotFixtureInput?.demurrage_rate) / 24));
            if (hasChanges) {
                setState((old) => ({
                    ...old,
                    dirty: true,
                    loadPorts: { ...loadPorts },
                    dischargePorts: { ...dischargePorts },
                    cargoInfos: { ...cargoInfos },
                    selected: {
                        ...old.selected,
                        spotFixtureInput: {
                            ...old.selected.spotFixtureInput,
                            total_used_laytime: totalUsedLaytime,
                            calculated_demurrage: calculatedDemurrage
                        }
                    }
                }));
            }
        }
    }, [
        state.cargoInfos,
        state.customDemurrageTimes,
        state.loadPorts,
        state.dischargePorts,
        state.selected.spotFixture?.id,
        state.selected.spotFixtureInput?.demurrage_rate,
        state.selected.spotFixtureInput?.laytime,
        state.selected.spotFixtureInput,
        setState
    ]);
}
