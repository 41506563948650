import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, createContext } from 'react';
import api from '../utils/api';
export var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["DIRECTOR"] = "DIRECTOR";
    Role["PARTNER"] = "PARTNER";
    Role["ACCOUNT"] = "ACCOUNT";
    Role["OPERATOR"] = "OPERATOR";
    Role["BROKER"] = "BROKER";
    Role["ALL"] = "ALL";
})(Role || (Role = {}));
export const AuthContext = createContext({
    user: null,
    setUser() { },
    userSettings: null,
    setUserSettings() { },
    logOut() { }
});
function logOut() {
    localStorage.removeItem('current-user');
    api.setToken('');
    window.location.reload();
}
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [userSettings, setUserSettings] = useState(null);
    useEffect(() => {
        const userStrFromLocalStorage = localStorage.getItem('current-user');
        if (userStrFromLocalStorage != null && userStrFromLocalStorage.length > 0) {
            const userFromLocalStorage = JSON.parse(userStrFromLocalStorage);
            if (userFromLocalStorage != null) {
                setUser(userFromLocalStorage);
                api.setToken(userFromLocalStorage.token);
            }
        }
        const userSettingsStrFromLocalStorage = localStorage.getItem('current-user-settings');
        if (userSettingsStrFromLocalStorage != null &&
            userSettingsStrFromLocalStorage.length > 0) {
            const userSettingsFromLocalStorage = JSON.parse(userSettingsStrFromLocalStorage);
            if (userSettingsFromLocalStorage != null) {
                setUserSettings(userSettingsFromLocalStorage);
            }
        }
    }, []);
    useEffect(() => {
        if (user != null) {
            localStorage.setItem('current-user', JSON.stringify(user));
        }
    }, [user]);
    useEffect(() => {
        if (userSettings != null) {
            localStorage.setItem('current-user-settings', JSON.stringify(userSettings));
        }
    }, [userSettings]);
    return (_jsx(AuthContext.Provider, { value: {
            user,
            setUser,
            userSettings,
            setUserSettings,
            logOut
        }, children: children }));
}
