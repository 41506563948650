export var ContentType;
(function (ContentType) {
    ContentType["JSON"] = "application/json";
    ContentType["FORM"] = "multipart/form-data";
})(ContentType || (ContentType = {}));
class Api {
    baseUrl;
    tokenStr = '';
    headers;
    constructor(baseUrl = '') {
        this.baseUrl = baseUrl;
        this.headers = {
            Accept: 'application/json'
        };
    }
    async get(endpoint) {
        return await this.request('GET', endpoint, undefined, {
            ...this.headers,
            ...(this.tokenStr.length > 0
                ? { Authorization: `Bearer ${this.tokenStr}` }
                : undefined)
        });
    }
    async post(endpoint, payload) {
        return await this.request('POST', endpoint, payload);
    }
    async postForm(endpoint, payload) {
        return await fetch(`${this.getBaseUrl()}${Api.clearEndpoint(endpoint)}`, {
            method: 'POST',
            headers: {
                ...this.headers,
                ...(this.tokenStr.length > 0
                    ? { Authorization: `Bearer ${this.tokenStr}` }
                    : undefined)
            },
            body: payload
        });
    }
    async put(endpoint, payload) {
        return await this.request('PUT', endpoint, payload);
    }
    async patch(endpoint, payload) {
        return await this.request('PATCH', endpoint, payload);
    }
    async delete(endpoint) {
        return await this.request('DELETE', endpoint);
    }
    getBaseUrl() {
        if (this.baseUrl.endsWith('/')) {
            this.baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1);
        }
        return this.baseUrl;
    }
    static clearEndpoint(endpoint) {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`;
        }
        return endpoint;
    }
    setToken(tokenStr) {
        this.tokenStr = tokenStr;
    }
    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl;
    }
    async request(method, endpoint, payload, headers = {
        ...this.headers,
        'Content-Type': ContentType.JSON,
        ...(this.tokenStr.length > 0
            ? { Authorization: `Bearer ${this.tokenStr}` }
            : undefined)
    }) {
        return await fetch(`${this.getBaseUrl()}${Api.clearEndpoint(endpoint)}`, {
            method,
            headers,
            body: payload != null
                ? JSON.stringify({ ...payload }, function (key, value) {
                    if (this[key] instanceof Date) {
                        return this[key].toJSON();
                    }
                    return value;
                })
                : undefined
        }).then((r) => {
            if (r.status === 401) {
                localStorage.clear();
                location.replace('/login');
            }
            return r;
        });
    }
}
const api = new Api();
export default api;
